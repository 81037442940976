import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PageWithHeader from '../components/pageWithHeader';
import style from './capability-category.module.scss';
import { LinkCard } from '../components/LinkCard';
import Img from 'gatsby-image';
import { SecondaryAnchorButton } from '../components/buttons';
import { Icon } from '../components/icon';
import { DataComponent } from '../helpers/dataHelpers';

function hasSummary(data) {
    return data.summaryTitle &&
        data.summaryDescription &&
        data.summaryButton &&
        data.summaryImage;
}

const CapabilityCategory = ({ pageContext }) => {
    const data = useStaticQuery<GatsbyTypes.CapabilityCategoryQuery>(graphql`
    query CapabilityCategory {
        capabilities: allContentfulCapability(sort: {fields: order}) {
            edges {
                node {
                  name
                  slug
                  node_locale
                  photo {
                      contentful_id
                      localFile {
                          childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                category {
                    slug
                }
            }
        }
    }
    }`);

    return <CapabilityCategoryInner data={data} pageData={pageContext.data} ></CapabilityCategoryInner>
}

export default CapabilityCategory;
type CapabilityCategoryProps = {
    pageData: {
        title: string,
        slug: string,
        headerImage: any,
        summaryFlipDirection: string,
        summaryTitle: string,
        summaryDescription: {
            summaryDescription: string;
        },
        summaryDownload: any,
        summaryButton: string,
        summaryImage: any,
    }
}
const CapabilityCategoryInner: DataComponent<GatsbyTypes.CapabilityCategoryQuery, CapabilityCategoryProps> = ({data, pageData}) => {
    const capabilities = data.capabilities.edges.filter(x => x.node?.category?.slug === pageData?.slug).map(c => c.node);
    return (
        <PageWithHeader title={pageData.title} headerImage={pageData.headerImage.fluid.src} >
            <article className={style.article}>
                {hasSummary(pageData) && 
                    <div className={style.summary} style={{'flexDirection': pageData.summaryFlipDirection ? 'row-reverse' : 'row'}}>
                        <Img className={style.summaryImage} fluid={pageData.summaryImage.localFile.childImageSharp.fluid}/>
                        <div className={style.summaryBody}>
                            <h2>{pageData.summaryTitle}</h2>
                            <p>{pageData.summaryDescription.summaryDescription}</p>
                            <div className={style.summaryButton}>
                                <SecondaryAnchorButton href={pageData.summaryDownload.file.url} download>{pageData.summaryButton} <Icon className={style.download}>get_app</Icon></SecondaryAnchorButton>
                            </div>
                        </div>
                    </div>
                }
                <div className={style.list}>
                    {capabilities.map(c => (
                        <div key={c.name} className={style.card}>
                            <LinkCard
                                title={c.name}
                                link={`/capabilities/${c.slug}`}
                                image={c.photo}
                            ></LinkCard>
                        </div>
                    ))}
                </div>
            </article>
            <div className={style.articleFooter}>
                <h3>Powerful Solution. Effective Support.</h3>
                <Link to="/contact/"><button>Contact us</button></Link>
            </div>
        </PageWithHeader>
    );
}